import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import AppPolicy from "../components/AppPolicy"

const AppTermsOfService = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        privacyAndTerm {
          termsOfService
        }
      }
    }
  `)
  const { termsOfService } = data.strapi.privacyAndTerm

  return (
    <Layout showHeader={false} showFooter={false}>
      <SEO title="Terms of Service" />
      <AppPolicy content={termsOfService} />
    </Layout>
  )
}

export default AppTermsOfService
