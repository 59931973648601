import React from "react"
import styled from "styled-components"

import MarkdownContent from "./ui/MarkdownContent"

const Container = styled.div`
  max-width: 1240px;
  padding: 10px;
  margin: 0 auto;
`

const AppPolicy = ({ content }: { content: string }) => {
  return (
    <Container>
      <MarkdownContent animate={false} content={content} />
    </Container>
  )
}

export default AppPolicy
